<template>
  <div style="padding-bottom:10px;">
    <div class="textF">{{ Text }}</div>
    <div class="valueF">{{ value }}</div>
  </div>
</template>

<script>
export default {
  props: {
    Text: String,
    value: String,
  },
};
</script>
<style scoped>
.textF {
  color: rgb(94, 94, 94);
  font-size: 0.9rem;
}
.valueF {
  color: #000;
  font-size: 1.2rem;
  padding: 3px 0px;
}
</style>