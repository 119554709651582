<template>
  <div class="pa-2">
    <v-card class="pa-2 mb-2" flat>
      <v-row>
          <v-col cols="12" md="8">
          <v-text-field
            v-model="txtsearch"
            dense
            solo-inverted
            flat
            label="Enter Name"
            hide-details
            style="width:100%;"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-select
            outlined
            dense
            :items="venue"
            label="Select Venue"
            item-text="venue_name"
            item-value="venue_id"
            v-model="selVenue"
            return-object
          >
          </v-select>
        </v-col>
      
        <v-col cols="12" md="2">
          <v-btn medium color="primary" @click="btnsearch" class="ml-1">
            <v-icon left>mdi-reload</v-icon>Search</v-btn
          >
        </v-col>
      </v-row>
    </v-card>

    <v-card class="pa-1 mb-2" flat>
      <v-row>
        <v-col cols="12" md="9" sm="12" v-if="desserts.length > 0">
        </v-col>
        <v-col cols="12" md="3" sm="12" v-if="desserts.length > 0">
          <v-text-field
          text-md-right
            v-model="search"
            dense
            solo-inverted
            flat
            label="Search"
            hide-details
            style="width:100%;"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="12" v-if="desserts.length > 0">
          GENERAL APPLICATION
          <v-data-table
            v-model="TDselected"
            :headers="headers"
            :items="desserts"
            :search="search"
            item-key="application_id"
            loading-text="Loading... Please wait"
            style="border-radius: 0px"
          >
            <template v-slot:top>
              <v-card flat style="position: sticky; top: 0px; z-index: 8">
                <v-card-actions>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </template>
            <!-- @click="BtnApprove(index)" -->
            <template v-slot:item.application_id="{ item }">
              <tr @click="editItem(
                        item.event_id,
                        item.venue_id,
                        item.vendor_id,
                        item.application_id
                      )">
                <!-- <td style="text-align: left; width: 100px">{{ item.app_id }}</td> -->
                <td style="text-align: left; width: 65px">
                  <img
                    :src="$apiPath + item.picHeadShot"
                    height="60px"
                    width="60px"
                    style="border-radius: 10px; border: 1px solid #ccc"
                    type="button"
                  />
                </td>
              </tr>
            </template>
<!-- 
            <template v-slot:item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="
                      editItem(
                        item.event_id,
                        item.venue_id,
                        item.vendor_id,
                        item.application_id
                      )
                    "
                  >
                    mdi-eye-outline
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </template> -->

            <template v-slot:no-data>
              <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
              No Record(s)
            </template>
          </v-data-table>
        </v-col>

        <v-col cols="12" md="12" v-if="dessertsmedia.length > 0">
          MEDIA APPLICATION
          <v-data-table
            v-model="TDselected"
            :headers="headersmedia"
            :items="dessertsmedia"
            :search="search"
            item-key="application_id"
            loading-text="Loading... Please wait"
            style="border-radius: 0px"
          >
            <template v-slot:top>
              <v-card flat style="position: sticky; top: 0px; z-index: 8">
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <!-- <v-text-field
                v-model="search"
                dense
                solo-inverted
                flat
                label="Search"
                hide-details
                style="max-width: 400px"
              ></v-text-field> -->
                </v-card-actions>
              </v-card>
            </template>
            <!-- @click="BtnApprove(index)" -->
            <template v-slot:item.application_id="{ item }">
              <tr  @click="editItemMedia(item.event_id, item.application_id)">
                <!-- <td style="text-align: left; width: 100px">{{ item.app_id }}</td> -->

                <td style="text-align: left; width: 65px">
                  <img
                    :src="$apiPath + item.photo"
                    height="60px"
                    width="60px"
                    style="border-radius: 10px; border: 1px solid #ccc"
                    type="button"
                  />
                </td>
              </tr>
            </template>

            <!-- <template v-slot:item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                   
                  >
                    mdi-eye-outline
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </template> -->

            <template v-slot:no-data>
              <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
              No Record(s)
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog
      transition="dialog-top-transition"
      max-width="750px"
      v-model="dialogedit"
    >
      <v-card flat class="pa-1 mb-2">
        <v-toolbar color="primary" flat dense dark>
          Application Detail
          <v-spacer></v-spacer>
          <v-icon text @click="dialogedit = false">mdi-close</v-icon>
        </v-toolbar>
        <v-card-text class="mt-5">
          <div id="panAllData">
            <table width="100%" style="max-width:750px;">
              <tr>
                <td style="text-align: left; width: 30% !important">
                  <FieldValue Text="App ID" :value="cApp.app_id"> </FieldValue>
                </td>
                <td style="text-align: right; width: 60% !important">
                  <FieldValue Text="Venue" :value="cApp.venue_name">
                  </FieldValue>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <hr/>
                </td>
              </tr>

              <tr>
                <td style="text-align: left; width: 30% !important;vertical-align:top;">
                  <img
                    :src="$apiPath + cApp.picHeadShot"
                    height="180px"
                    width="180px"
                    style="border-radius: 10px; border: 1px solid #ccc"
                    type="button"
                  />
                </td>

                <td
                  style="
                    text-align: left;
                    width: 70%;
                    vertical-align: text-top;
                    font-size: 17px;
                  "
                >
                      <FieldValue Text="Name" :value="cApp.fName + cApp.lName">
                      </FieldValue>
                 <FieldValue Text="Date of Birth" :value="cApp.dob">
                      </FieldValue>
                   <FieldValue Text="Mobile" :value="cApp.mobile">
                      </FieldValue>
                 
                   <FieldValue Text="Email" :value="cApp.email">
                      </FieldValue>
                 
                   <FieldValue Text="Address" :value="cApp.addLine1 + cApp.addLine2">
                      </FieldValue>
                 
                   <FieldValue Text="Company" :value="cApp.company_name">
                      </FieldValue>
                  
                   <FieldValue Text="Company Type" :value="cApp.company_type">
                      </FieldValue>
                 
                   <FieldValue Text="Job Type" :value="cApp.jobtype_name">
                      </FieldValue>
                 
                  <FieldValue Text="Card Type" :value="cApp.card_name">
                      </FieldValue>
                      <FieldValue Text="Status" :value="cApp.status_named">
                      </FieldValue>
                  <br />


                 
                </td>
              </tr>

              <!-- <tr>
                <td
                  style="
                    text-align: left;
                    width: 100%;
                    font-size: 25px;
                    background-color: #cdc5c5;
                  "
                  colspan="2"
                >
                  Company Details
                </td>
              </tr> -->
              <!-- <tr>
                <td
                  colspan="2"
                  style="
                    text-align: left;
                    width: 100%;
                    font-size: 17px;
                    padding-top: 10px;
                  "
                >
                  Name-{{ cApp.company_name }}<br />
                  Address-{{ cApp.company_address }}<br />
                  Company Type-{{ cApp.companytype_name }}<br />
                </td>
              </tr> -->

              <tr>
                <td
                  colspan="2"
                  style="
                    text-align: left;
                    width: 100%;
                    font-size: 25px;
                    background-color: #cdc5c5;
                  "
                >
                  ID FRONT
                </td>
              </tr>
              <tr>
                <td
                  colspan="2"
                  style="text-align: left; width: 100% !important"
                >
               
                  <img
                  
                    :src="$apiPath + cApp.picIdFront"
                    width="100%"
                    height="400px"
                    style="border-radius: 10px; border: 1px solid #ccc"
                    type="button"
                  />
                 
                </td>
              </tr>

              <tr>
                <td
                  colspan="2"
                  style="
                    text-align: left;
                    width: 100%;
                    font-size: 25px;
                    background-color: #cdc5c5;
                  "
                >
                  ID BACK
                </td>
              </tr>
              <tr>
                <td
                  colspan="2"
                  style="text-align: left; width: 100% !important"
                >
                  <img
                    :src="$apiPath + cApp.picIdBack"
                    width="100%"
                    height="400px"
                    style="border-radius: 10px; border: 1px solid #ccc"
                    type="button"
                  />
                </td>
              </tr>
            </table>
          </div>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
            color="success"
            v-simple-print="'panAllData'"
            v-if="desserts.length > 0"
            >Print

            <v-icon>mdi-file-pdf-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      transition="dialog-top-transition"
      max-width="750px"
      v-model="dialogeditmedia"
    >
      <v-card flat class="pa-1 mb-2">
        <v-toolbar color="primary" flat dense dark>
          Media Application Detail
          <v-spacer></v-spacer>
          <v-icon text @click="dialogeditmedia = false">mdi-close</v-icon>
        </v-toolbar>
        <v-card-text class="mt-5">
          <div id="panAllDatamedia">
            <table width="100%" style="max-width:750px;">

<tr>
                <td style="text-align: left; width: 30% !important">
                  <FieldValue Text="App ID" :value="cAppMedia.app_id"> </FieldValue>
                </td>
                <td style="text-align: right; width: 60% !important">
                  <FieldValue Text="Venue" :value="cAppMedia.venue_name">
                  </FieldValue>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <hr/>
                </td>
              </tr>


              <tr>
               

<td style="text-align: left; width: 30% !important;vertical-align:top;">
                  <img
                    :src="$apiPath + cAppMedia.photo"
                    height="180px"
                    width="180px"
                    style="border-radius: 10px; border: 1px solid #ccc"
                    type="button"
                  />
                </td>


                <td
                  style="
                    text-align: left;
                    width: 70%;
                    vertical-align: text-top;
                    font-size: 17px;
                  "
                >
                      <FieldValue Text="Name" :value="cAppMedia.firstName + cAppMedia.lastName">
                      </FieldValue>
                       <FieldValue Text="Dob" :value="cAppMedia.dob">
                      </FieldValue>
                       <FieldValue Text="Mobile" :value="cAppMedia.mobile">
                      </FieldValue>
                       <FieldValue Text="Email" :value="cAppMedia.email">
                      </FieldValue>
                       <FieldValue Text="Address" :value="cAppMedia.addLine1 + cAppMedia.addLine2">
                      </FieldValue>
                       <FieldValue Text="Media Name" :value="cAppMedia.mediaName">
                      </FieldValue>
                      <FieldValue Text="Company Type" :value="cAppMedia.mediaType_name">
                      </FieldValue>
                      <FieldValue Text="Status" :value="cAppMedia.status_named">
                      </FieldValue>
               
                 
                </td>
              </tr>
              <tr>
                <td
                  colspan="2"
                  style="
                    text-align: left;
                    width: 100%;
                    font-size: 25px;
                    background-color: #cdc5c5;
                  "
                >
                  ID FRONT
                </td>
              </tr>
              <tr>
                <td
                  colspan="2"
                  style="text-align: left; width: 100% !important"
                >
                  <img
                    :src="$apiPath + cAppMedia.photoIdFront"
                    width="100%"
                    height="400px"
                    style="border-radius: 10px; border: 1px solid #ccc"
                    type="button"
                  />
                </td>
              </tr>

              <tr>
                <td
                  colspan="2"
                  style="
                    text-align: left;
                    width: 100%;

                    font-size: 25px;
                    background-color: #cdc5c5;
                  "
                >
                  ID BACK
                </td>
              </tr>
              <tr>
                <td
                  colspan="2"
                  style="text-align: left; width: 100% !important"
                >
                  <img
                    :src="$apiPath + cAppMedia.photoIDBack"
                    width="100%"
                    height="400px"
                    style="border-radius: 10px; border: 1px solid #ccc"
                    type="button"
                  />
                </td>
              </tr>
            </table>
          </div>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
            color="success"
            v-simple-print="'panAllDatamedia'"
            v-if="desserts.length > 0"
            >Print

            <v-icon>mdi-file-pdf-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//import html2canvas from "html2canvas";
//import jsPDF from "jspdf";
//import FieldValue from "./FieldValue";
import FieldValue from "../../components/Admin/FieldValueReport.vue";
export default {
  components: { FieldValue },
  data() {
    return {
      venue: [],
      dialogedit: false,
      dialogeditmedia: false,
      search: "",
      txtsearch: "",
      selVenue: {
        venue_id:"0"
      },
      desserts: [],
      dessertsmedia: [],
      TDselected: [],
      cApp: [],
      cAppMedia: [],

      headersmedia: [
        {
          text: "AppID",
          align: "left",
          value: "app_id",
          class: "blue lighten-5 w-140",
        },
        {
          text: "Photo",
          align: "left",
          value: "application_id",
          class: "blue lighten-5 w-140",
        },

        {
          text: "First Name",
          value: "firstName",
          align: "left",
          class: "blue lighten-5",
        },
        {
          text: "Last Name",
          value: "lastName",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Venue",
          value: "venue_name",
          align: "left",
          class: "blue lighten-5",
        },

         {
          text: "Organisation",
          value: "mediaName",
          align: "left",
          class: "blue lighten-5",
        },

        // {
        //   text: "Mediatype",
        //   value: "mediaType_name",
        //   align: "left",
        //   class: "blue lighten-5",
        // },
       

         {
           text: "Organisation Type",
          value: "organisationType_name",
           align: "left",
           class: "blue lighten-5",
         },
        

        {
          text: "Designation",
          value: "role_name",
          align: "left",
          class: "blue lighten-5",
        },
         {
          text: "Status",
          value: "status_name",
          align: "left",
          class: "blue lighten-5",
        },
        


       
      ],

      headers: [
        {
          text: "AppID",
          align: "left",
          value: "app_id",
          class: "blue lighten-5 w-140",
        },
        {
          text: "Photo",
          align: "left",
          value: "application_id",
          class: "blue lighten-5 w-140",
        },

        {
          text: "First Name",
          value: "fName",
          align: "left",
          class: "blue lighten-5",
        },
        {
          text: "Last Name",
          value: "lName",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Venue",
          value: "venue_name",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Company",
          value: "company_name",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Designation",
          value: "jobtype_name",
          align: "left",
          class: "blue lighten-5",
        },
        {
          text: "Status",
          value: "status_name",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Card Type",
          value: "card_name",
          align: "left",
          class: "blue lighten-5",
        }
        

        
      ],
    };
  },

  mounted() {
    this.$store.commit("setPageTitle", "Global Search");
    this.$store.commit("setPageTitleIcon", "mdi-file-chart");
    this.bindVenue(this.$store.state.eventInfo.EventId);
  },
  methods: {
    // exportPDF() {
    //   window.html2canvas = html2canvas;
    //   var doc = new jsPDF("p", "pt", "a4");
    //   doc.html(document.querySelector("#panAllData"), {
    //     callback: function (pdf) {
    //       pdf.save("PoliceReport.pdf");
    //     },
    //   });
    // },

    editItem: function (event_id, venue_id, vendor_id, application_id) {
      this.dialogedit = true;
      this.bindDetaildata(event_id, venue_id, vendor_id, application_id);
    },

    editItemMedia: function (event_id, application_id) {
      this.dialogeditmedia = true;
      this.bindDetailMedeidata(event_id, application_id);
    },

    bindDetaildata: async function (
      eventid,
      venueid,
      vendorid,
      application_id
    ) {
      //  alert("vendorid" + vendorid + "appid-" +  appid)
      this.overlay = 1;
      await this.$axios
        .post("Application/AppDetails", {
          event_id: eventid,
          venue_id: venueid,
          vendor_id: vendorid,
          application_id: application_id,
        })
        .then((res) => {
          this.cApp = res.data.result;
          console.log(this.cApp);
        });
    },

    bindDetailMedeidata: async function (eventid, application_id) {
      //  alert("vendorid" + vendorid + "appid-" +  appid)
      this.overlay = 1;
      await this.$axios
        .get("Media/Details/" + eventid + "/" + application_id)
        .then((res) => {
          this.cAppMedia = res.data.result;
          console.log("Media");
          console.log(this.cAppMedia);
        });
    },
    btnsearch: function () {
      this.Reload(
        this.$store.state.eventInfo.EventId,
        this.selVenue.venue_id,
        this.txtsearch
      );
    },
    Reload: async function (event_id, venue_id, searchName) {
      //  this.loadingstatus = true;
      // alert(event_id + "/" + venue_id + "/" + company_id + "/" + status_id + "/" + card_id);
      this.overlay = true;
      await this.$axios
        .get(
          "Application/ByNameVnId/" +
            event_id +
            "/" +
            venue_id +
            "/" +
            searchName
        )
        .then((res) => {
          console.log(res.data.result);
          this.desserts = res.data.result;

          this.loadingstatus = false;
          this.overlay = false;
        })
        .catch()
        .finally();

      await this.$axios
        .get("Media/ByNameVnId/" + event_id + "/" + venue_id + "/" + searchName)
        .then((res) => {
          console.log(res.data.result);
          this.dessertsmedia = res.data.result;

          this.loadingstatus = false;
          this.overlay = false;
        })
        .catch()
        .finally();
    },

    bindVenue: async function (eventID) {
      await this.$axios.get("Venue/GetByEId/" + eventID).then((res) => {
        this.venue = res.data.result;
        // this.Company.splice(0,0,{venue_id: "0", company_name: "All" });
        this.venue.splice(0, 0, { venue_id: "0", venue_name: "All" });
        //this.selVenue.se
      });
    },
  },
};
</script>

<style scoped>
.thheader {
  background-color: rgba(0, 132, 255, 0.658);
  text-align: center;
  width: 100px !important;
  font-size: 9px;
  font-family: Arial;
  padding: 8px 0px;
}
.thdata {
  text-align: center;

  padding: 8px 0px;
  border: 1px solid #ccc;
}
</style>